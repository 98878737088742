<template>
  <div class="teacher-home">
    <div id="teacher-menu-top">
      <div>
        <div class="title">
          {{ app.strings.app.teacher.home.teacher }}
        </div>
        <div class="name">
          {{ app.user.name }}
        </div>
      </div>
      <img src="@/assets/images/mates.png" />
    </div>
    <div id="teacher-biometric-courses-content">
      <div class="title">
        {{ app.strings.app.teacher.biometric.course.list.title }}
      </div>
      <div class="course" v-for="course in courses.data" v-bind:key="course.reference">
        <div v-on:click="openBiometricCourse(course.id)">
          {{ course.reference }} - {{ course.name }}
        </div>
      </div>
      <div v-if="courses.loading === false && courses.data.length === 0" id="courses-empty">
        {{ app.strings.app.teacher.biometric.course.list.empty }}
      </div>
    </div>
    <div class="app-back">
      <button class="h-button default w-100 mb-10" v-on:click="openBiometricCourse('all')" v-if="!courses.loading">
        {{ app.strings.app.teacher.biometric.course.list.all }} ({{ courses.data.length }})
      </button>
      <template v-if="iframe === false">
        <button class="h-button success w-100" v-on:click="$router.back()" v-if="!courses.loading">
          {{ app.strings.common.back }}
        </button>
        <button class="h-button success w-100" v-else>
          <loader-dot :size="'16px'" :color_1="'rgb(255, 255, 255)'" :color_2="'rgb(255, 255, 255)'" />
        </button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-teacher-biometric-courses-view',
  components: {
  },
  data () {
    return {
      iframe: false,
      courses: {
        loading: false,
        data: []
      }
    }
  },
  computed: {
  },
  props: [
    'app'
  ],
  mounted () {
    this.biometricCourseReadAll()
    
    this.iframe = this.$route.query.iframe === true || this.$route.query.iframe === 'true'
  },
  methods: {
    biometricCourseReadAll () {
      if (this.courses.loading) {
        return
      }

      this.courses.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.biometric.course.read.all,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.courses.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.courses.data = response.body.data.courses !== null ? response.body.data.courses : []
            localStorage['app-teacher-courses'] = JSON.stringify(this.courses.data)
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'biometric-course-read-all', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.courses.loading = false
        this.courses.data = JSON.parse(localStorage['app-teacher-courses'])
      })
    },
    openBiometricCourse (id) {
      this.$router.push({name: 'app.teacher.biometric.course', params: {id: id}, query: {origin: 'app', navigationLeft: 'false', iframe: this.iframe, token: this.app.token}})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";
@import "@/assets/styles/mobile-app.scss";

.teacher-home {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

#teacher-biometric-courses-content {
  flex: 1;
  overflow: auto;
}

#teacher-biometric-courses-content .title {
  font-weight: bold;
  font-size: 22px;
  color: $color-info;
  padding: 12px 25px;
}

.course {
  padding: 6px 12px;
  box-sizing: border-box;
}

.course > div {
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.course > div:nth-child(odd) {
  background-color: rgb(180, 180, 180);
}

.course > div:nth-child(odd) {
  background-color: rgb(160, 160, 160);
}

#courses-empty {
  font-weight: bold;
  font-size: 16px;
  color: $color-danger;
  padding: 12px 25px;
}
</style>
