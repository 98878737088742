<template>
  <div class="biometric-register">
    <div id="biometric-register-content" :style="'background: url(\'' + require('@/assets/images/background_gradient_blue.png') + '\') no-repeat;'" v-if="type == ''">
      <div id="biometric-register-selector">
        <img src="@/assets/images/biometric_register_avatar_1.png" />
        <div id="biometric-register-title">
          <span>{{ app.strings.biometric.register.title_1 }}</span>
          <br>
          <span>{{ app.strings.biometric.register.title_2 }}</span>
        </div>
        <div id="biometric-register-data">
          <input type="text" class="default w-100" :value="biometric.data.student.first_name + ' ' + biometric.data.student.last_name_1 + ' ' + biometric.data.student.last_name_2" disabled/>
          <div id="biometric-register-data-question" v-if="visible">
            {{ app.strings.biometric.register.question }}
          </div>
          <div id="biometric-register-data-options" v-if="visible">
            <div>
              <div v-on:click="fingerprintStart()">
                <div>
                  <img src="@/assets/images/fingerprint_icon.png" />
                </div>
                <div>
                  {{ app.strings.biometric.register.register_with }}
                  <div v-html="app.strings.biometric.register.fingerprint"></div>
                </div>
              </div>
            </div>
            <div>
              <div v-on:click="facerecognitionStart()" v-if="this.faceapi.loaded">
                <div>
                  <img src="@/assets/images/facerecognition_icon.png" />
                </div>
                <div>
                  {{ app.strings.biometric.register.register_with }}
                  <div v-html="app.strings.biometric.register.facerecognition"></div>
                </div>
              </div>
              <div style="opacity: 0.5;" v-else>
                <div>
                  <img src="@/assets/images/facerecognition_icon.png" />
                </div>
                <div v-if="faceapi.loading !== undefined">
                  {{ app.strings.biometric.register.register_with_loading }}
                  <div>
                    {{ faceapi.loading.state + '/' + faceapi.loading.states }}
                    <br>
                    {{ faceapi.loading.percent }} %
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="biometric-register-content" :style="'background: url(\'' + require('@/assets/images/background_gradient_green.png') + '\') no-repeat;'" v-if="type == 'fingerprint'">
      <div id="biometric-register-fingerprint">
        <img src="@/assets/images/fingerprint_indicator.png" />
        <div>
          {{ app.strings.biometric.register.fingerprint_lector }}
        </div>
      </div>
    </div>

    <div id="biometric-register-content" :style="'background: url(\'' + require('@/assets/images/background_gradient_blue.png') + '\') no-repeat;'" v-if="type == 'facerecognition'">
      <div id="biometric-register-facerecognition" v-if="!faceRegistering">
        <video ref="face-video-src" autoplay playsinline muted  style="pointer-events: none;"></video>
        <div class="overlay-canvas">
          <canvas ref="face-overlay" />
          <canvas ref="face-canvas" style="display: none;" />
        </div>
        <div class="overlay-top">
          <div>
            {{ app.strings.biometric.register.facerecognition_instruction }}
          </div>
        </div>
        <div class="overlay-bottom">
          <button class="h-button orange w-100" v-on:click="toggleCamera()" v-if="!cameraChange">
            <font-awesome-icon :icon="['fas', 'sync']" />
          </button>
          <button class="h-button orange w-100 disabled" v-else>
            <font-awesome-icon :icon="['fas', 'sync']" />
          </button>

          <div style="width: 20px"></div>

          <button class="h-button default w-100" v-on:click="registerFace()" v-if="faceCanBeRegistered && !faceRegistering">
            {{ app.strings.biometric.register.facerecognition_register }}
          </button>
          <button class="h-button default w-100 disabled" v-if="!faceCanBeRegistered && !faceRegistering">
            {{ app.strings.biometric.register.facerecognition_searching }}
          </button>
          <button class="h-button default w-100 disabled" v-if="faceRegistering">
            {{ app.strings.biometric.register.facerecognition_registering }}
          </button>

          <!--<div id="camera-toggle">
            <font-awesome-icon :icon="['fas', 'sync']" v-on:click="toggleCamera()" />
          </div>-->
        </div>
      </div>
      <div style="width: 100%;height: 100%;" v-else>
        <loader-dot :size="'20px'" style="width: 100%;height: 100%;display: flex;" />
      </div>
    </div>
  </div>
</template>

<script>
import FaceApi from '@/modules/face-api/face-api.module'

export default {
  name: 'biometric-register-view',
  components: {
  },
  data () {
    return {
      type: '',
      request: '',
      iframe: false,
      recognition: false,
      visible: false,
      biometric: {
        loading: false,
        data: {
          student: {
            first_name: '',
            last_name_1: '...',
            last_name_2: ''
          },
          fingerprint: ''
        }
      },
      cameras: [],
      cameraIndex: 0,
      cameraChange: false,
      cameraStream: '',
      faceapi: '',
      faceCanBeRegistered: false,
      faceRegistering: false,
      faceWithBoxes: false
    }
  },
  computed: {
  },
  props: [
    'app'
  ],
  mounted () {
    this.request = this.$route.query.request

    this.loadFaceApi()

    this.biometricRead()

    this.biometric.fingerprint = this.$route.query.biometricdata
    if (this.biometric.fingerprint != undefined) {
      this.type = 'fingerprint'
      this.registerBiometric('fingerprint', this.biometric.fingerprint)
    }

    this.iframe = this.$route.query.iframe === true || this.$route.query.iframe === 'true'
    this.recognition = this.$route.query.recognition == 'true'

    if (this.recognition === true) {
      this.facerecognitionStart()
    }
  },
  methods: {
    async loadFaceApi () {
      console.log('Loading face api...')
      this.faceapi = new FaceApi('https://www.auesgestion.com', true)
    },
    biometricRead () {
      if (this.biometric.loading) {
        return
      }

      this.biometric.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.biometric.student.request + this.request
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            let data = response.body.data.biometric
            data.student = response.body.data.student
            this.biometric.data = data
            this.visible = true
          } else {
            let error = response.body.error.code
            this.showError(this.app.strings, 'biometric-request', error)
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.biometric.loading = false
      }, () => {
        this.biometric.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    fingerprintStart () {
      this.type = 'fingerprint'

      let url = window.location.href
      let arr = url.split("/")
      let scheme = arr[0].replace(':', '')

      let data = btoa(JSON.stringify({
        action: 'register',
        callback: url
      }))

      if (scheme === 'http' || scheme === 'https') {
        window.open('https://www.auesgestion.com/biometric/fingerprint/launch/' + data, '_blank')
        window.close()
      }
    },
    async facerecognitionStart () {
      if (this.iframe === true && this.recognition === false) {
        window.open(window.location.href + '&recognition=true', '_blank').focus()
        return
      }
      navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then(() => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          for (let i in devices) {
            let device = devices[i]
            if (device.kind === 'videoinput') {
              this.cameras.push(device)
            }
          }

          this.type = 'facerecognition'

          this.openCamera()
        })
      })
    },
    async openCamera () {
      this.cameraChange = true

      let camera = this.cameras[this.cameraIndex]

      if (this.cameraStream !== '') {
        let tracks = this.cameraStream.getTracks()
        tracks.forEach(track => track.stop())
      }

      this.cameraStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: camera.deviceId
        },
        audio: false
      })

      this.$refs['face-video-src'].srcObject = this.cameraStream
      this.$refs['face-video-src'].onloadedmetadata = this.onPlay

      this.cameraChange = false
    },
    async toggleCamera () {
      this.cameraIndex++
      if (this.cameraIndex > this.cameras.length - 1) {
        this.cameraIndex = 0
      }
      this.openCamera()
    },
    async onPlay () {
      const videoEl = this.$refs['face-video-src']

      if(videoEl.paused || videoEl.ended || !this.faceapi.isFaceDetectionModelLoaded() || this.cameraChange) {
        return setTimeout(() => this.onPlay())
      }

      const ts = Date.now()

      const result = await window.faceapi.detectSingleFace(videoEl, this.faceapi.getFaceDetectorOptions()).withFaceLandmarks()

      this.faceapi.updateTimeStats(Date.now() - ts)

      if (result) {
        this.faceCanBeRegistered = true

        const canvas = this.$refs['face-overlay']
        const dims = window.faceapi.matchDimensions(canvas, videoEl, true)
        const resizedResult = window.faceapi.resizeResults(result, dims)

        if (this.faceWithBoxes) {
          window.faceapi.draw.drawDetections(canvas, resizedResult)
        }
        // faceapi.draw.drawFaceLandmarks(canvas, resizedResult)
        const faceLandmarksArray = Array.isArray(result) ? result : [result]
        faceLandmarksArray.forEach(f => {
          new window.faceapi.draw.DrawFaceLandmarks(f.landmarks, new window.faceapi.draw.DrawFaceLandmarksOptions({

            drawLines: true,
            lineWidth: 1,
            lineColor: '#FFFFFF',
            drawPoints: true,
            pointSize: 2,
            pointColor: '#FFFFFF'
          })).draw(canvas)
        })
      } else {
        this.faceCanBeRegistered = false
      }

      if (this.cameraStream !== '') {
        setTimeout(() => this.onPlay())
      }
    },
    registerFace () {
      if (this.faceRegistering) {
        return
      }

      this.faceRegistering = true

      let video = this.$refs['face-video-src']
      let canvas = this.$refs['face-canvas']
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)

      let faceReference = canvas.toDataURL('image/png')

      this.cameraStream.getTracks()[0].stop()
      this.cameraStream = ''

      this.registerFaceConfirmation(faceReference)
    },
    async registerFaceConfirmation (faceReference) {
      // this.registerBiometric('facerecognition', faceReference)
      let data = await this.faceapi.getFaceDetection(faceReference)
      if (data !== null) {
        data = JSON.stringify(data)
        this.registerBiometric('facerecognition', data, faceReference)
      } else {
        let error = 'NULL detector'
        this.showError(this.app.strings, 'biometric-update', error)
      }
    },
    registerBiometric(type, data, image) {
      let params = {
        request: this.request,
        target: type,
        data: data
      }

      if (type === 'facerecognition') {
        params['image'] = image
      }

      this.$http.post(
        this.app.url.api + this.app.endpoints.biometric.student.update,
        params,
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.faceRegistering = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.type = ''
            this.$swal({
              title: this.app.strings.biometric.register.success.title,
              text: this.app.strings.biometric.register.success.content,
              icon: 'success'
            }).then(() => {
              this.visible = false
            })
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'biometric-update', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.faceRegistering = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.biometric-register {
  background-color: $color-primary-1;
  padding: 0 !important;
}

#biometric-register-content {
  width: 100%;
  height: 100%;
  background-size: 100% 100% !important;
}

#biometric-register-selector {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin: auto;
  padding: 25px 25px 75px 25px;
  box-sizing: border-box;
  flex-direction: column;
}

#biometric-register-selector > img {
  position: absolute;
  top: 75px;
  left: 0;
  display: block;
  max-width: 100%;
  z-index: 0;
}

#biometric-register-title {
  font-size: 35px;
  color: #FFF;
  font-weight: bold;
  flex: 1;
  z-index: 1000;
}

#biometric-register-title > span:nth-of-type(1) {
  color: $text-color-primary-inverse;
  font-size: 60px;
}

#biometric-register-data {
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

#biometric-register-data > input {
  opacity: 1 !important;
  background: #FFFFFF !important;
  text-align: center;
  padding: 15px;
  box-sizing: border-box;
}

#biometric-register-data-question {
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  padding: 15px;
  box-sizing: border-box;
}

#biometric-register-data-options {
  display: flex;
  flex: 1;
}

#biometric-register-data-options > div {
  flex: 1;
  height: 150px;
  padding: 0 12px;
}

#biometric-register-data-options > div > div {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 6px;
}

#biometric-register-data-options > div > div > div {
  flex: 1;
  box-sizing: border-box;
}

#biometric-register-data-options > div:nth-of-type(1) > div {
  background-color: $color-success;
}

#biometric-register-data-options > div:nth-of-type(2) > div {
  background-color: $color-info;
}

#biometric-register-data-options > div > div > div > img {
  display: block;
  height: 50px;
}

#biometric-register-data-options > div > div > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 12px 6px 12px;
}

#biometric-register-data-options > div > div > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 12px 12px 12px;
}

#biometric-register-data-options > div > div > div:nth-of-type(2) > div {
  font-size: 18px;
}

#biometric-register-fingerprint {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

#biometric-register-fingerprint > img {
  width: 90%;
}

#biometric-register-fingerprint > div {
  font-size: 30px;
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
  padding: 25px;
}

#biometric-register-facerecognition {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#biometric-register-facerecognition > video {
}

#biometric-register-facerecognition > .overlay-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#biometric-register-facerecognition > .overlay-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  padding: 25px;
  box-sizing: border-box;
}

#biometric-register-facerecognition > .overlay-bottom {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  padding: 25px;
  box-sizing: border-box;
}

#camera-toggle {
  display: flex;
  padding: 0 12px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: #FFF;
}
</style>
